import BaseService from "./BaseService";

class SaleService extends BaseService {

  // Charts
  async getDonut(req) {
    const response = await this.axios.post('/sale/doughnut', req);
    return response.data;
  }

  async getHistoricLine() {
    const response = await this.axios.get('/sale/historic');
    return response.data;
  }

  async getGroupBy(req) {
    const response = await this.axios.post('/sale/group', req);
    return response.data;
  }

  async getTodayData() {
    const response = await this.axios.post('/sale/today');
    return response.data;
  }

  async getOnlineSales(req) {
    const response = await this.axios.post('/sale/online', req);
    return response.data;
  }

  async getList(req) {
    const response = await this.axios.post('/sale/list', req);
    return response.data;
  }

  async getRecentSales(req) {
    const response = await this.axios.post('/sale/recent', req);
    return response.data;
  }

}

export default SaleService;
